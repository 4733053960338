import axios from "axios";

export const GetFullURL = (url) => {
    debugger
    let baseURL ="https://demonwizard-001-site1.gtempurl.com/";

    // If the base URL doesn't end with a slash, add one
    if (baseURL && !baseURL.endsWith('/')) {
        baseURL += '/';
    }

    // If the url is undefined or null, replace with an empty string
    if (!url) {
        url = '';
    }

    // Concatenate the base URL with the url, removing any 'undefined' part
    return (baseURL + url).replace('undefined', '');
};

export const SendRequest =   (config) => {
    const token = localStorage.getItem("jwt_token");

    if (token !== null) {
        if (config.headers === undefined) config.headers = {};
        config.headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response =  axios({
            method: "post",
            data: {},
            ...config,
            url: GetFullURL(config.url),
            withCredentials: true // Ensure this is included if your server requires credentials
        });
        return response;
    } catch (error) {
        console.error('There was an error!', error);
        throw error; // Rethrow the error for further handling if needed
    }
}